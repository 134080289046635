import karagiannakidis from './assets/karagiannakidis.jpg';
import krikoni from './assets/krikoni.jpg';
import tachtanis from './assets/tachtanis.jpg';
import Kazuyo from './assets/Kazuyo.jpg';
import Koutsogiannis from './assets/Koutsogiannis.jpeg';
import Zigkeridis from './assets/Zigkeridis.jpg';
import Vasilis from './assets/Vasilis.jpg';

const MEMBER_LIST = [
  {
    id: 1,
    name: 'Karagiannakidis',
    photo: karagiannakidis,
  },
  {
    id: 2,
    name: 'Koutsogiannis',
    photo: Koutsogiannis,
  },
  {
    id: 3,
    name: 'Krikoni',
    photo: krikoni,
  },
  {
    id: 4,
    name: 'Kazuyo',
    photo: Kazuyo,
  },
  {
    id: 5,
    name: 'Tsachtanis',
    photo: tachtanis,
  },
  {
    id: 6,
    name: 'Zigkeridis',
    photo: Zigkeridis,
  },
  {
    id: 7,
    name: 'Vasilis',
    photo: Vasilis,
  },
];

export default MEMBER_LIST;
