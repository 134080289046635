import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LandingCarousel from './LandingCarousel';
import Welcome from './Welcome';
import ComingEvents from './ComingEvents';
import Supporters from './Supporters';
import { getMedia } from 'src/actions/media';

const HomePageContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMedia());
  }, [dispatch]);

  return (
    <>
      <LandingCarousel />
      <Welcome />
      <ComingEvents />
      <Supporters />
    </>
  );
};

export default HomePageContainer;
